import qs from "qs";
import * as React from "react";
import { useState } from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin";
import { useLocation } from "react-router-dom";
import Pagination from "../Components/Pagination";
import { CLAIM_TITLE_FIELD } from "../claim/ClaimTitle";

export const PaymentList = (props: ListProps): React.ReactElement => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  let jsonFilter: any = {
    isPlanned: "",
  };

  if (parsedLocation.filter) {
    try {
      jsonFilter = JSON.parse(parsedLocation.filter as string);
    } catch (e) {}
  }

  const [referenceFilterValue, setReferenceFilterValue] = useState(
    jsonFilter.businessName?.toString() || undefined
  );

  const paymentFilters = [
    <SelectInput
      alwaysOn
      source="paymentType"
      label="Payment Type"
      choices={[
        { label: "CreditorInterest", value: "CreditorInterest" },
        { label: "DunningCostExpense", value: "DunningCostExpense" },
        { label: "DunningCostFee", value: "DunningCostFee" },
        { label: "DefaultInterest", value: "DefaultInterest" },
        {
          label: "ExistingCreditorExtras",
          value: "ExistingCreditorExtras",
        },
        {
          label: "ExistingPayments",
          value: "ExistingPayments",
        },
        { label: "WriteOffDiscount", value: "WriteOffDiscount" },
        { label: "DebtClearance", value: "DebtClearance" },
        { label: "PaymentRate", value: "PaymentRate" },
        { label: "Payout", value: "Payout" },
        {
          label: "DebtCollectorTax",
          value: "DebtCollectorTax",
        },
        {
          label: "DebtCollectorFee",
          value: "DebtCollectorFee",
        },
        {
          label: "DebtCollectorOutboundPosition",
          value: "DebtCollectorOutboundPosition",
        },
        {
          label: "DebtCollectorInboundPosition",
          value: "DebtCollectorInboundPosition",
        },
        {
          label: "ClaimPosition",
          value: "ClaimPosition",
        },
      ]}
      optionText="label"
      optionValue="value"
    />,
    <SearchInput
      source="reference"
      alwaysOn
      placeholder="Reference"
      autoFocus
      fullWidth
      onChange={(event) => {
        if (event.target?.value && event.target.value.length > 2) {
          setReferenceFilterValue(event?.target.value.trim());
        } else {
          setReferenceFilterValue(undefined);
        }
      }}
    />,
  ];

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title={"Payments"}
      filter={{
        ...(!!referenceFilterValue
          ? {
              reference: { contains: referenceFilterValue || "" },
            }
          : {
              reference: { not: undefined },
            }),
      }}
      exporter={false}
      filters={paymentFilters}
      perPage={50}
      empty={false}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        optimized
        style={{
          marginTop: "1rem",
        }}
      >
        <ReferenceField
          label="Claim"
          source="claim.id"
          reference="Claim"
          link="show"
        >
          <TextField source={CLAIM_TITLE_FIELD} />
        </ReferenceField>
        <DateField
          label="Payment Date"
          source="paymentDate"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <TextField label="Payment Type" source="paymentType" />
        <TextField label="Amount" source="amount" />
        <TextField label="Reference" source="reference" />
        <DateField
          source="createdAt"
          label="Created At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
        <DateField
          source="updatedAt"
          label="Updated At"
          locales="de-DE"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "Europe/Berlin",
          }}
        />
      </Datagrid>
    </List>
  );
};
