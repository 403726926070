import { AppBar, Layout, UserMenu } from "react-admin";

// Custom UserMenu with Logout button
const CustomUserMenu = (props) => {
  return <UserMenu {...props}></UserMenu>;
};

// Custom AppBar that uses CustomUserMenu
const CustomAppBar = (props) => {
  return <AppBar {...props} userMenu={<CustomUserMenu />} />;
};

// Custom Layout that uses CustomAppBar
const CustomLayout = (props) => {
  return (
    <Layout {...props} appBar={CustomAppBar}>
      {props.children}
    </Layout>
  );
};

export default CustomLayout;
