import { Tooltip } from "@material-ui/core";
import {
  Assignment,
  FileCopyOutlined,
  Gavel,
  InfoRounded,
  Mail,
  MoneyOff,
  Notifications,
  PersonAdd,
  Visibility,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import {
  FunctionField,
  ReferenceField,
  TextField,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";
import { ADDRESS_COUNTRY_SHORT } from "../api/address/Address";
import { EnumAddressCountry } from "../api/address/EnumAddressCountry";
import { theme } from "../theme/theme";
import { searchMapByKey } from "../util/MapUtils";
import { stringToHslColor } from "../util/StyleUtils";

export const ClaimHeaderBar = React.memo<any>(
  ({
    record,
    notify,
    hasLawyers,
    hasInsolvencyAdministrators,
    statusToShow,
  }) => {
    return (
      <div className="claimHeaderBar">
        <div className="bar-item">
          <TextField
            style={{ fontWeight: 600 }}
            label="Reference (Internal Identifier)"
            source="reference"
          />
          <FileCopyOutlined
            style={{
              height: 12,
              cursor: "copy",
              position: "relative",
              top: 1,
            }}
            onClick={() => {
              navigator.clipboard.writeText(record?.reference);
              notify("Copied to clipboard", "info", null, false);
            }}
          />
        </div>
        <ReferenceField
          link="show"
          label="Creditor"
          source="creditor.id"
          reference="User"
          className="bar-item"
        >
          <CreditorSummary />
        </ReferenceField>
        <ReferenceField
          link="show"
          label="Debtor"
          source="debtor.id"
          reference="User"
          className="bar-item"
        >
          <DebtorSummary
            claim={record}
            hasLawyers={hasLawyers}
            hasInsolvencyAdministrators={hasInsolvencyAdministrators}
            className="bar-item"
          />
        </ReferenceField>
        <FunctionField
          label="Stage"
          className="bar-item"
          render={(record: any) => (
            <span
              style={{
                maxWidth: 200,
                fontWeight: 600,
                whiteSpace: "nowrap",
                background: ["Paid", "PaidOut"].includes(statusToShow)
                  ? theme.palette.success.dark
                  : statusToShow === "Paused"
                  ? theme.palette.grey[800]
                  : statusToShow === "Open"
                  ? theme.palette.primary.main
                  : ["Closed", "Cancelled"].includes(statusToShow)
                  ? theme.palette.error.main
                  : record?.stage === "Court"
                  ? theme.palette.error.dark
                  : record?.stage === "Precourt"
                  ? theme.palette.warning.main
                  : record?.stage === "Reminder"
                  ? theme.palette.info.main
                  : theme.palette.info.dark,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                padding: "7px 10px",
                fontSize: 12,
                borderRadius: 8,
              }}
            >
              {record?.stage === "Precourt" ? (
                <Mail style={{ height: 14 }} />
              ) : record?.stage === "Court" ? (
                <Gavel style={{ height: 14 }} />
              ) : record?.stage === "Monitoring" ? (
                <Visibility style={{ height: 14 }} />
              ) : record?.stage === "Reminder" ? (
                <Notifications style={{ height: 14 }} />
              ) : (
                <></>
              )}
              {`${record?.stage && record?.stage} - ${statusToShow}`}
            </span>
          )}
        />
      </div>
    );
  }
);

const DebtorSummary = ({
  claim,
  hasLawyers,
  hasInsolvencyAdministrators,
}: any): React.ReactElement => {
  const record = useRecordContext();
  const amountOfClaims = record?.claimsAsDebtorIds?.length;
  const positionInArray = record?.claimsAsDebtorIds?.indexOf(claim?.id);

  const dataProvider = useDataProvider();
  const [country, setCountry] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const address = await dataProvider.getOne("Address", {
        id: record?.address?.id,
      });
      if (address?.data?.country) {
        setCountry(address?.data?.country);
      }
    };
    fetchData();
  }, [dataProvider, record?.address?.id, record?.phone]);

  const iso2CoutryCode = searchMapByKey(
    ADDRESS_COUNTRY_SHORT,
    country as EnumAddressCountry
  );

  return (
    <>
      <FunctionField
        label="BusinessType"
        style={{ color: theme.palette.error.main }}
        render={(record: any) => (
          <span
            title="Business Type"
            style={{
              color:
                record?.businessType === "Consumer" ? "#995200" : "#000099",
              background:
                record?.businessType === "Consumer" ? "#ffc480" : "#d1d1ff",
              padding: "5px 10px",
              marginRight: 8,
              fontSize: 12,
              fontWeight: "bold",
              borderRadius: 8,
            }}
          >
            {record?.businessType === "Consumer" ? "B2C" : "B2B"}
          </span>
        )}
      />
      <FunctionField
        label="Name"
        render={(record: any) => (
          <span
            style={{
              color: theme.palette.error.dark,
              fontWeight: 700,
            }}
          >
            {iso2CoutryCode && iso2CoutryCode !== "de" && (
              <ReactCountryFlag
                countryCode={iso2CoutryCode}
                svg
                style={{ marginRight: 4 }}
              />
            )}
            {record?.businessName || record?.contactName}
            {record?.comment && (
              <Tooltip title={record.comment}>
                <InfoRounded
                  style={{
                    height: 18,
                    position: "relative",
                    top: 4,
                    color: theme.palette.primary.main,
                  }}
                />
              </Tooltip>
            )}
          </span>
        )}
      />
      {amountOfClaims && amountOfClaims > 1 && (
        <span
          title="Gesamtanzahl Akten"
          style={{
            background: theme.palette.secondary.main,
            color: "white",
            padding: "5px 10px",
            marginLeft: 10,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <Assignment
            style={{
              height: 12,
              width: 12,
              marginRight: 2,
              position: "relative",
              top: 2,
            }}
          />
          {(positionInArray > 0 ? positionInArray + 1 : "1") +
            " / " +
            amountOfClaims}
        </span>
      )}
      {hasLawyers === true && (
        <span
          title="Anwalt"
          style={{
            background: theme.palette.error.dark,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <Gavel
            style={{
              height: 12,
              width: 12,
              position: "relative",
              top: 2,
            }}
          />
        </span>
      )}
      {hasInsolvencyAdministrators === true && (
        <span
          title="Insolvenzverwalter"
          style={{
            background: theme.palette.error.dark,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 25,
          }}
        >
          <MoneyOff
            style={{
              height: 12,
              width: 12,
              position: "relative",
              top: 2,
            }}
          />
        </span>
      )}
      {claim?.contactsIds?.length > 0 ? (
        <span
          title="Weitere Kontakte"
          style={{
            background: theme.palette.error.main,
            color: "white",
            padding: 5,
            marginLeft: 3,
            fontSize: 12,
            fontWeight: "bold",
            borderRadius: 8,
          }}
        >
          <PersonAdd
            style={{
              height: 12,
              width: 12,
              marginRight: 2,
              position: "relative",
              top: 2,
            }}
          />
          {claim?.contactsIds?.length}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

const CreditorSummary = (): React.ReactElement => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [language, setLanguage] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const address = await dataProvider.getOne("Address", {
        id: record?.address?.id,
      });
      if (address?.data?.languageCountry) {
        setLanguage(address?.data?.languageCountry);
      }
    };
    fetchData();
  }, [dataProvider, record?.address?.id, record?.phone]);

  const iso2CoutryCode = searchMapByKey(
    ADDRESS_COUNTRY_SHORT,
    language as EnumAddressCountry
  );

  return (
    <>
      <FunctionField
        label="Business Name"
        render={(record: any) => (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                alignItems: "center",
                width: 35,
                height: 20,
                marginRight: 4,
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 8,
                lineHeight: "20px",
                verticalAlign: "middle",
                textAlign: "center",
                borderStyle: "solid",
                borderColor: stringToHslColor(record?.businessName, 70),
                color: stringToHslColor(record?.businessName, 30),
                background: stringToHslColor(record?.businessName, 75),
              }}
            >
              {record?.businessName?.slice(0, 3).toUpperCase()}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                fontWeight: 600,
                verticalAlign: "middle",
                color: theme.palette.success.dark,
              }}
            >
              {iso2CoutryCode && iso2CoutryCode !== "de" && (
                <ReactCountryFlag
                  countryCode={iso2CoutryCode}
                  svg
                  style={{ marginRight: 4 }}
                />
              )}
              {record?.businessName}
              {record?.comment && (
                <Tooltip title={record.comment}>
                  <InfoRounded
                    style={{ height: 18, color: theme.palette.primary.main }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
};
