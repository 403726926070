import { Grid, Typography } from "@material-ui/core";
import * as React from "react";
import {
  DateField,
  FunctionField,
  ReferenceField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { ADDRESS_COUNTRY_READABLE } from "../api/address/Address";
import { USER_TITLE_FIELD } from "../user/UserTitle";

export const AddressShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = React.useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Address/" + props.id + "/edit", "_self");
      }
    },
    [props.id]
  );

  React.useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Address Line 1"}
            </Typography>
            <TextField label="Address Line 1" source="addressLine1" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Verification Status"}
            </Typography>
            <TextField
              label="Verification Status"
              source="verificationStatus"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Address Line 2"}
            </Typography>
            <TextField label="Address Line 2" source="addressLine2" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Created At"}
            </Typography>
            <DateField
              source="createdAt"
              label="Created At"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Postalcode"}
            </Typography>
            <TextField label="Postalcode" source="postalcode" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Updated At"}
            </Typography>
            <DateField
              source="updatedAt"
              label="Updated At"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Town"}
            </Typography>
            <TextField label="Town" source="town" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"User"}
            </Typography>
            <ReferenceField
              link="show"
              label="User"
              source="user.id"
              reference="User"
            >
              <TextField source={USER_TITLE_FIELD} />
            </ReferenceField>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Country"}
            </Typography>
            <FunctionField
              label="Country"
              render={(record: any) => (
                <span>{ADDRESS_COUNTRY_READABLE.get(record?.country)}</span>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            {" "}
            <Typography variant="caption" style={{ display: "block" }}>
              {"ID"}
            </Typography>
            <TextField label="ID" source="id" />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
