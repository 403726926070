import { JsonValue } from "type-fest";
import { Claim } from "../claim/Claim";
import { User } from "../user/User";
import { EnumActivityActivityType } from "./EnumActivityActivityType";
import { EnumActivityClaimAction } from "./EnumActivityClaimAction";
import { EnumActivityResult } from "./EnumActivityResult";

export type Activity = {
  activityDate: Date | null;
  activityType?:
    | "OutboundCall"
    | "OutboundMail"
    | "OutboundLetter"
    | "OutboundWhatsapp"
    | "OutboundSms"
    | "Response"
    | "Other"
    | "InternalFeedback"
    | "CreditorInformation"
    | "CreditorFeedback"
    | "Research"
    | "JudicialActivity"
    | "InsolvencyActivity"
    | "InternalDecision"
    | "CreditorDecision"
    | "RecommendedOutboundMail"
    | " RecommendedInternalFeedback";
  claim?: Claim;
  claimAction?:
    | "FirstReminder"
    | "SecondReminder"
    | "ThirdReminder"
    | "FirstDunning"
    | "SecondDunning"
    | "ThirdDunning"
    | "FourthDunning"
    | "FifthDunning"
    | "SixthDunning"
    | "PhoneResearch"
    | "EmailResearch"
    | "OnlineResearch"
    | "PublicAuthorityRequest"
    | "DefaultSummon"
    | "Lawsuit"
    | "DebtCollectionTransfer"
    | null;
  comment: string | null;
  createdAt: Date;
  fileUrl: string | null;
  fileUrls: JsonValue;
  id: string;
  isPlanned: boolean;
  manager?: User | null;
  result?:
    | "NotReached"
    | "Reached"
    | "ReachedPromisedToPay"
    | "ReachedIgnored"
    | "ReachedRefusedToPay"
    | "ReachedRefusedCorrectness"
    | "ReachedPaymentPlanSetup"
    | "ReachedPaymentPlanAdjustment"
    | "ReachedPaymentPlanStop"
    | "Positive"
    | "Negative"
    | null;
  updatedAt: Date;
};

export function getReadableSubjectByClaimAction(
  claimAction: EnumActivityClaimAction | string
): string {
  switch (claimAction) {
    case EnumActivityClaimAction.FirstDunning:
      return "1. Mahnung";
    case EnumActivityClaimAction.SecondDunning:
      return "2. Mahnung";
    case EnumActivityClaimAction.ThirdDunning:
      return "3. Mahnung";
    case EnumActivityClaimAction.FourthDunning:
      return "Anwaltsschreiben (4.)";
    case EnumActivityClaimAction.FifthDunning:
      return "Können vs wollen (5.)";
    case EnumActivityClaimAction.SixthDunning:
      return "Überwachungsnachricht (6.)";
    case EnumActivityClaimAction.FirstReminder:
      return "1. Zahlungserinnerung";
    case EnumActivityClaimAction.SecondReminder:
      return "2. Zahlungserinnerung";
    case EnumActivityClaimAction.ThirdReminder:
      return "3. Zahlungserinnerung";
    case EnumActivityClaimAction.PhoneResearch:
      return "Telefon Recherche";
    case EnumActivityClaimAction.OnlineResearch:
      return "Internet Recherche (Anschrift)";
    case EnumActivityClaimAction.EmailResearch:
      return "E-Mail Recherche";
    case EnumActivityClaimAction.PublicAuthorityRequest:
      return "Behördenanfrage";
    case EnumActivityClaimAction.DefaultSummon:
      return "Mahnbescheid";
    case EnumActivityClaimAction.Lawsuit:
      return "Klage";
    case EnumActivityClaimAction.DebtCollectionTransfer:
      return "Inkasso-Transfer";

    default:
      return claimAction;
  }
}

export function getReadableActivityType(
  activityType: EnumActivityActivityType | string
): string {
  switch (activityType) {
    case EnumActivityActivityType.OutboundCall:
      return "Anruf";
    case EnumActivityActivityType.OutboundLetter:
      return "Brief";
    case EnumActivityActivityType.OutboundMail:
      return "E-Mail";
    case EnumActivityActivityType.OutboundSms:
      return "SMS";
    case EnumActivityActivityType.OutboundWhatsapp:
      return "WhatsApp";
    case EnumActivityActivityType.CreditorFeedback:
      return "Nachricht von Gläubiger";
    case EnumActivityActivityType.CreditorDecision:
      return "Gläubiger Entscheidung";
    case EnumActivityActivityType.InternalFeedback:
      return "Anfrage an Gläubiger";
    case EnumActivityActivityType.CreditorInformation:
      return "Information an Gläubiger";
    case EnumActivityActivityType.InternalDecision:
      return "Interne Entscheidung";
    case EnumActivityActivityType.RecommendedOutboundMail:
      return "AI: Schuldner-Antwort ✨";
    case EnumActivityActivityType.RecommendedInternalFeedback:
      return "AI: Gläubigeranfrage ✨";
    case EnumActivityActivityType.Research:
      return "Recherche";
    case EnumActivityActivityType.JudicialActivity:
      return "Gerichtliche Aktivität";
    case EnumActivityActivityType.InsolvencyActivity:
      return "Insolvenz Aktivität";
    case EnumActivityActivityType.Response:
      return "Schuldner-Antwort";
    case EnumActivityActivityType.Other:
      return "Anderes";

    default:
      return activityType;
  }
}

export function getReadableResult(result: EnumActivityResult | string): string {
  switch (result) {
    case EnumActivityResult.NotReached:
      return "Nicht erreicht";
    case EnumActivityResult.Reached:
      return "Erreicht";
    case EnumActivityResult.ReachedIgnored:
      return "Erreicht (Ignoriert)";
    case EnumActivityResult.ReachedPaymentPlanAdjustment:
      return "Erreicht (Zahlungsplananpassung)";
    case EnumActivityResult.ReachedPaymentPlanSetup:
      return "Erreicht (Zahlungsplanstart)";
    case EnumActivityResult.ReachedPaymentPlanStop:
      return "Erreicht (Zahlungsplanstopp)";
    case EnumActivityResult.ReachedPromisedToPay:
      return "Erreicht (Zahlungsversprechen)";
    case EnumActivityResult.ReachedRefusedCorrectness:
      return "Erreicht (Forderung abgelehnt)";
    case EnumActivityResult.ReachedRefusedToPay:
      return "Erreicht (Zahlung abgelehnt)";
    case EnumActivityResult.Positive:
      return "Genehmigt";
    case EnumActivityResult.Negative:
      return "Abgelehnt";

    default:
      return result;
  }
}
