import * as React from "react";

import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { Grid } from "@material-ui/core";
import { UserTitle } from "../user/UserTitle";

export const IntegrationCreate = (props: CreateProps): React.ReactElement => {
  return (
    <Create {...props}>
      <SimpleForm>
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={4}>
            <DateTimeInput label="Last Sync Date" source="lastSyncDate" />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              required
              source="software"
              label="Software"
              choices={[
                { label: "lexoffice", value: "Lexoffice" },
                { label: "sevDesk", value: "SevDesk" },
                { label: "FastBill", value: "FastBill" },
                { label: "BuchhaltungsButler", value: "BuchhaltungsButler" },
                { label: "Sage", value: "Sage" },
                { label: "Zoho Books", value: "ZohoBooks" },
                { label: "Odoo", value: "Odoo" },
                { label: "Chargebee", value: "Chargebee" },
                { label: "DATEV", value: "Datev" },
                { label: "Billomat", value: "Billomat" },
                { label: "Circuly", value: "Circuly" },
                { label: "Payactive", value: "Payactive" },
                { label: "Billbee", value: "Billbee" },
                { label: "Xentral", value: "Xentral" },
                { label: "Sage", value: "Sage" },
                { label: "easybill", value: "Easybill" },
                { label: "Buhl", value: "Buhl" },
                { label: "Stripe", value: "Stripe" },
                { label: "(Custom)", value: "Custom" },
              ]}
              optionText="label"
              allowEmpty
              optionValue="value"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              required
              label="Token"
              source="token"
              defaultValue={
                Math.random().toString(36).substring(2, 8) +
                Math.random().toString(36).substring(2, 8) +
                Math.random().toString(36).substring(2, 8) +
                Math.random().toString(36).substring(2, 8)
              }
            />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput
              filterToQuery={(searchText) => ({
                businessName: { startsWith: searchText },
              })}
              source="user.id"
              reference="User"
              label="Business Name *"
            >
              <AutocompleteInput suggestionLimit={5} optionText={UserTitle} />
            </ReferenceInput>
          </Grid>
          <Grid item xs={8}>
            <TextInput
              label="Reply-To Email Address"
              source="replyToEmailAddress"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Custom Email Address"
              source="customEmailAddress"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Custom Email Selector"
              source="customEmailAddressSelector"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              label="Custom Email Key"
              source="customEmailAddressKey"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput label="B2C Identifier" source="b2cIdentifier" />
          </Grid>
          <Grid item xs={4}>
            <TextInput label="B2B Identifier" source="b2bIdentifier" />
          </Grid>
          <Grid item xs={3}>
            <TextInput label="User Identifier" source="userIdentifier" />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              label="Organization Identifier"
              source="organizationIdentifier"
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput label="Domain Identifier" source="domainIdentifier" />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              label="Database Identifier"
              source="databaseIdentifier"
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Days overdue to fetch (Dunning)"
              source="daysOverDueToFetch"
              defaultValue={30}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Days due to fetch for first reminder"
              source="daysDueToFetchForFirstReminder"
              defaultValue={7}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Days due to fetch for second reminder"
              source="daysDueToFetchForSecondReminder"
              defaultValue={14}
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              label="Days due to fetch for third reminder"
              source="daysDueToFetchForThirdReminder"
              defaultValue={20}
            />
          </Grid>
        </Grid>
        <ArrayInput label="Debtor Blacklist" source="debtorBlacklist">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          label="Is Custom Email Address Verified"
          source="isCustomEmailAddressVerified"
          style={{ display: "inline-block", marginTop: 20, width: "100%" }}
        />
        <BooleanInput
          label="Disable B2B"
          source="disableB2B"
          style={{ display: "inline-block", marginTop: 20, width: "100%" }}
        />
        <BooleanInput
          label="Disable B2C"
          source="disableB2C"
          style={{ display: "inline-block", marginTop: 20, width: "100%" }}
        />
        <BooleanInput
          label="Is Active"
          source="isActive"
          style={{ display: "inline" }}
        />
      </SimpleForm>
    </Create>
  );
};
