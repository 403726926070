import { FormLabel, Grid, Typography } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import * as React from "react";
import { useCallback, useEffect } from "react";
import {
  BooleanField,
  Button,
  DateField,
  FunctionField,
  ReferenceField,
  RichTextField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import {
  Activity,
  getReadableActivityType,
  getReadableResult,
  getReadableSubjectByClaimAction,
} from "../api/activity/Activity";
import { Claim } from "../api/claim/Claim";

let claimReference: string | null = "";

function handleFileClick(claimReference: string, url: string): void {
  const authHeader = {
    headers: { Authorization: localStorage.getItem("credentials") || "" },
  };

  const urlToFetch = url
    .split("media/" + claimReference.replaceAll("/", "-") + "/")
    .pop();

  if (urlToFetch) {
    fetch(
      process.env.REACT_APP_SERVER_URL +
        "/api/claims/" +
        claimReference.replaceAll("/", "-") +
        "/file/" +
        urlToFetch,
      authHeader
    ).then((response) => {
      if (response.ok) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        response.blob().then((blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = claimReference + "-" + urlToFetch;
          anchor.click();

          window.URL.revokeObjectURL(objectUrl);
        });
      }
    });
  }
}

const ActivityFileUrl = (): React.ReactElement => {
  const record: Activity = useRecordContext();

  return (
    <>
      <FormLabel style={{ fontSize: 12 }}>File URL</FormLabel>
      <br />
      <TextField emptyText="-" label="File URL" source="fileUrl" />
      {record?.fileUrl &&
        record.fileUrl !== null &&
        claimReference !== null && (
          <Button
            variant="outlined"
            size="medium"
            startIcon={<ArrowDownward />}
            style={{ marginLeft: 10 }}
            onClick={() => handleFileClick(claimReference!, record.fileUrl!)}
            label="Download File"
          />
        )}
      <br />
      <TextField emptyText="-" label="File URLs" source="fileUrls" />
    </>
  );
};

const ClaimReference = (): React.ReactElement => {
  const record: Claim = useRecordContext();

  useEffect(() => {
    claimReference = record?.reference;
  }, [record?.reference]);

  return (
    <>
      <TextField source="reference" />
    </>
  );
};

export const ActivityShow = (props: ShowProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/Activity/" + props.id + "/edit", "_self");
      }
    },
    [props.id]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Activity Type"}
            </Typography>
            <FunctionField
              label="Activity Type"
              render={(record: any) => (
                <span>{getReadableActivityType(record?.activityType)}</span>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Activity Date"}
            </Typography>
            <DateField
              label="Activity Date"
              source="activityDate"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Claim Action"}
            </Typography>
            <FunctionField
              label="Claim Action"
              emptyText="-"
              render={(record: any) => (
                <span>
                  {getReadableSubjectByClaimAction(record?.claimAction)}
                </span>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Claim"}
            </Typography>
            <ReferenceField
              label="Claim"
              source="claim.id"
              reference="Claim"
              link="show"
            >
              <ClaimReference />
            </ReferenceField>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Result"}
            </Typography>
            <FunctionField
              label="Result"
              render={(record: any) => (
                <span>{getReadableResult(record?.result)}</span>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Manager"}
            </Typography>
            <ReferenceField
              link="show"
              label="Manager"
              source="manager.id"
              reference="User"
              emptyText="(auto)"
            >
              <FunctionField
                label="Manager"
                render={(record: any) => (
                  <span>{record?.username?.split("@")?.[0]}</span>
                )}
              />
            </ReferenceField>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Is Planned"}
            </Typography>
            <BooleanField label="Is Planned" source="isPlanned" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Created At"}
            </Typography>
            <DateField
              source="createdAt"
              label="Created At"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ActivityFileUrl />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Updated At"}
            </Typography>
            <DateField
              source="updatedAt"
              label="Updated At"
              locales="de-DE"
              options={{
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: "Europe/Berlin",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"ID"}
            </Typography>
            <TextField label="ID" source="id" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ display: "block" }}>
              {"Comment"}
            </Typography>
            <RichTextField
              label="Comment"
              source="comment"
              style={{ whiteSpace: "pre-wrap" }}
            />
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
