import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import { Divider, Grid, Typography } from "@material-ui/core";
import { Help, RemoveCircle, VerifiedUser } from "@material-ui/icons";
import qs from "qs";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditProps,
  NumberInput,
  PasswordInput,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  maxLength,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";
import { AddressTitle } from "../address/AddressTitle";
import { PaymentInformationTitle } from "../paymentInformation/PaymentInformationTitle";
import { theme } from "../theme/theme";
import { ROLES_OPTIONS } from "./RolesOptions";
import { UserTitle } from "./UserTitle";

const AddressInput = () => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      fullWidth
      filterToQuery={(searchText) => ({
        addressLine1: { startsWith: searchText || "*" },
      })}
      source="address.id"
      reference="Address"
      label="Address"
      create
    >
      <AutocompleteInput
        suggestionLimit={1}
        width="100%"
        createValue="-- NEW --"
        optionText={AddressTitle}
        clearAlwaysVisible
        onSelect={(selectedItem) => {
          if (selectedItem?.id === "-- NEW --") {
            window.open("/#/Address/create?userId=" + record.id, "_blank");
          }
        }}
      />
    </ReferenceInput>
  );
};

const PaymentInformationInput = () => {
  const record = useRecordContext();

  return (
    <ReferenceInput
      fullWidth
      filterToQuery={(searchText) => ({
        account: { startsWith: searchText || "*" },
      })}
      source="paymentInformation.id"
      reference="PaymentInformation"
      label="Payment Information"
      create
    >
      <AutocompleteInput
        suggestionLimit={5}
        createValue="-- NEW --"
        optionText={PaymentInformationTitle}
        onSelect={(selectedItem) => {
          if (selectedItem?.id === "-- NEW --") {
            window.open(
              "/#/PaymentInformation/create?userId=" + record.id,
              "_blank"
            );
          }
        }}
      />
    </ReferenceInput>
  );
};

const NameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (!manualMode) {
    let generatedName: string = "";

    if (values.contactName) {
      generatedName = values.contactName;
      if (values.businessName) {
        generatedName = generatedName + ", " + values.businessName;
      }
    } else if (values.businessName) {
      generatedName = values.businessName;
    }
    values.name = generatedName;
  }

  return (
    <TextInput
      hidden
      style={{ display: "none" }}
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      label="Name (generated)"
      source="name"
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

const UsernameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();
  if (!manualMode) {
    if (values.email) {
      values.username = values.email;
    } else {
      values.username = values.phone;
    }
  }

  return (
    <TextInput
      label="Username (generated)"
      source="username"
      InputProps={{
        autoComplete: "off",
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

const EmailInput = () => {
  const form = useForm();

  return (
    <TextInput
      fullWidth
      label="Email"
      source="email"
      type="email"
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        pasteContent = pasteContent.trim().replace(/[^a-zA-Z0-9._%+-@]+/g, "");
        form.change("email", pasteContent);
      }}
    />
  );
};

const Roles = (props: any) => {
  const { values } = useFormState();

  if (!props.isNormalUser && !values.roles) {
    values.roles = ["debtor"];
  }

  return (
    <SelectArrayInput
      style={!props.isNormalUser ? { display: "none" } : {}}
      source="roles"
      choices={ROLES_OPTIONS}
      required
      optionText="label"
      optionValue="value"
    />
  );
};

const UserEditForm = (props: any) => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();

  let isNormalUser = true;
  let isLaywerUser = false;
  let isEmployee = false;
  if (
    parsedLocation.userType &&
    parsedLocation.userType !== "Default" &&
    values &&
    values.userType !== "Default"
  ) {
    if (parsedLocation.userType === "Lawyer" || values.userType === "Lawyer") {
      isLaywerUser = true;
    }
    isNormalUser = false;
  }
  if (isNormalUser) {
    isEmployee = ["manager", "admin", "lightManager"].some((str) =>
      values.roles.includes(str)
    );
  }
  if (props.onlyShowEmployeePreferences) {
    return renderEmployeeSettings(props.inline);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3} style={{ display: "none" }}>
          <NameInput required fullWidth />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput fullWidth label="Contact Name" source="contactName" />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextInput
            fullWidth
            label="Business Name"
            source="businessName"
            required={isLaywerUser ? true : false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Roles isNormalUser={isNormalUser} />
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectInput
            source="businessType"
            label="Business Type"
            choices={[
              { label: "Business", value: "Business" },
              { label: "Consumer", value: "Consumer" },
              { label: "Merchant", value: "Merchant" },
            ]}
            optionText="label"
            optionValue="value"
          />
          <SelectInput
            source="userType"
            label="User Type"
            choices={[
              { label: "Default", value: "Default" },
              { label: "Lawyer", value: "Lawyer" },
              {
                label: "InsolvencyAdministrator",
                value: "InsolvencyAdministrator",
              },
              { label: "AdditionalContact", value: "AdditionalContact" },
            ]}
            optionText="label"
            allowEmpty
            optionValue="value"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <EmailInput />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput fullWidth label="Phone" source="phone" />
        </Grid>
        <Grid item xs={6} md={3}>
          <SelectInput
            source="emailVerificationStatus"
            label="Email Verification Status"
            choices={[
              {
                label: (
                  <>
                    <VerifiedUser
                      style={{
                        height: 14,
                        color: theme.palette.success.main,
                      }}
                    />{" "}
                    Valid
                  </>
                ),
                value: "VALID",
              },
              {
                label: (
                  <>
                    <RemoveCircle
                      style={{
                        height: 14,
                        color: theme.palette.error.main,
                      }}
                    />{" "}
                    Invalid
                  </>
                ),
                value: "INVALID",
              },
              {
                label: (
                  <>
                    <Help
                      style={{
                        height: 14,
                        color: theme.palette.warning.dark,
                      }}
                    />{" "}
                    Unknown
                  </>
                ),
                value: "UNKNOWN",
              },
            ]}
            optionText="label"
            defaultValue={"UNKNOWN"}
            optionValue="value"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SelectInput
            source="phoneVerificationStatus"
            label="Phone Verification Status"
            choices={[
              {
                label: (
                  <>
                    <VerifiedUser
                      style={{
                        height: 14,
                        color: theme.palette.success.main,
                      }}
                    />{" "}
                    Valid
                  </>
                ),
                value: "VALID",
              },
              {
                label: (
                  <>
                    <RemoveCircle
                      style={{
                        height: 14,
                        color: theme.palette.error.main,
                      }}
                    />{" "}
                    Invalid
                  </>
                ),
                value: "INVALID",
              },
              {
                label: (
                  <>
                    <Help
                      style={{
                        height: 14,
                        color: theme.palette.warning.dark,
                      }}
                    />{" "}
                    Unknown
                  </>
                ),
                value: "UNKNOWN",
              },
            ]}
            optionText="label"
            optionValue="value"
            defaultValue={"UNKNOWN"}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <AddressInput />
        </Grid>
        <Grid item xs={6} md={3}>
          <DateInput label="Birthday" fullWidth source="birthday" />
        </Grid>
        <Grid item xs={6} md={3}>
          <PaymentInformationInput />
        </Grid>

        <Grid item xs={6} md={3}>
          <UsernameInput
            fullWidth
            required
            style={!isNormalUser ? { display: "none" } : {}}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <PasswordInput
            style={!isNormalUser ? { display: "none" } : {}}
            fullWidth
            required
            label="Password"
            source="password"
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}></Grid>

        <Grid item xs={6} md={3}>
          <ReferenceInput
            filterToQuery={(searchText) => ({
              name: { startsWith: searchText },
              businessName: { startsWith: "Debtist" },
            })}
            isRequired
            source="manager.id"
            reference="User"
            label="Manager"
          >
            <AutocompleteInput suggestionLimit={10} optionText={UserTitle} />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Divider style={{ width: "100%", marginBottom: 30, marginTop: 30 }} />

      {isNormalUser && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <SelectInput
                fullWidth
                source="industry"
                label="Industry"
                choices={[
                  { label: "Fulfillment", value: "Fulfillment" },
                  { label: "Fintech", value: "Fintech" },
                  { label: "Marketplace", value: "Marketplace" },
                  { label: "Insurance", value: "Insurance" },
                  { label: "Healthcare", value: "Healthcare" },
                  { label: "Software", value: "Software" },
                  { label: "Mobility", value: "Mobility" },
                  { label: "Lending", value: "Lending" },
                  {
                    label: "PaymentServiceProvider",
                    value: "PaymentServiceProvider",
                  },
                  { label: "Banking", value: "Banking" },
                  { label: "Rental", value: "Rental" },
                  { label: "ECommerce", value: "ECommerce" },
                  { label: "Logistics", value: "Logistics" },
                  { label: "Wholesale", value: "Wholesale" },
                  { label: "Construction", value: "Construction" },
                ]}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
              <TextInput fullWidth label="VAT Id" source="vatId" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DateInput label="Birthday" fullWidth source="birthday" />
              <TextInput label="Image URL" source="imageUrl" />
            </Grid>
            <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
              <NumberInput
                step={1}
                label="Max. Zahlungsplan Monate"
                source="maximumPaymentPlanMonths"
                defaultValue={12}
              />
              <NumberInput
                step={1}
                label="Autom. Vergleiche % von Forderung"
                source="autoSettlementInPercent"
              />
              <NumberInput
                step={1}
                label="Erfolgspauschale Ausland %"
                source="successFeeInPercent"
              />
            </Grid>
            <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
              <NumberInput
                step={1}
                label="Mahnbescheid Min.-Betrag B2C"
                source="courtOrderMinimumB2C"
              />
              <NumberInput
                step={1}
                label="Mahnbescheid Min.-Betrag B2B"
                source="courtOrderMinimumB2B"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Account Status</Typography>
              <br />
              <BooleanInput
                label="Is verified"
                source="isVerified"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                step={1}
                label="Reminder Subscription Active"
                source="hasActiveReminderSubscription"
              />
              <BooleanInput
                step={1}
                label="+ Calling for Reminders"
                source="hasActiveReminderSubscriptionWithCalling"
              />
              <BooleanInput
                step={1}
                label="Credit Rating Subscription Active"
                source="hasActiveCreditRatingSubscription"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Operational Flags</Typography>
              <br />
              <BooleanInput
                label="Keine Einwohnermeldeamt-Anfrage (EMA)"
                source="disableRegistrationOfficeEnquiry"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Negativpauschale"
                source="disableCancellationFee"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Anwaltliches Mahnschreiben erlaubt"
                source="allowPartnerLawyerInteraction"
                defaultValue={true}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Kein gerichtliches Verfahren"
                source="disableCourtOrder"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Streitverfahren"
                source="disableCourtLitigation"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Insolvenzanmeldung"
                source="disableInsolvencyFiling"
                defaultValue={false}
                style={{ display: "inline" }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Accounting Flags</Typography>
              <br />
              <BooleanInput
                label="Vorsteuerabzugsberechtigt"
                source="taxDeductionAllowed"
                defaultValue={true}
                style={{ display: "inline" }}
              />
            </Grid>
          </Grid>
          <Divider />
          <br />
          <TextInput
            label="Custom Clauses"
            multiline
            fullWidth
            minRows={4}
            source="customClauses"
          />
          <ArrayInput
            label="Operational Recipients"
            source="operationalRecipients"
          >
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Financial Recipients" source="financialRecipients">
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
            label="Analytical Recipients"
            source="analyticalRecipients"
          >
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      )}

      {isEmployee && renderEmployeeSettings()}
      <TextInput
        label="Comment"
        multiline
        minRows={3}
        fullWidth
        source="comment"
      />
    </>
  );

  function renderEmployeeSettings(inline?: boolean) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={6} md={inline ? 4 : 2}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Min. amount B2C"
            source="minimumAmountB2C"
          />
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Min. amount B2B"
            source="minimumAmountB2B"
          />
        </Grid>
        <Grid item xs={6} md={inline ? 4 : 2} style={{ textAlign: "right" }}>
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Max. amount B2C"
            source="maximumAmountB2C"
          />
          <NumberInput
            step={1}
            max={999999999}
            validate={maxLength(9)}
            parse={(value: string) => {
              if (value.toString().length > 9) {
                value = value.toString().slice(0, 9);
              }
              return Number(value);
            }}
            helperText={false}
            label="Max. amount B2B"
            source="maximumAmountB2B"
          />
        </Grid>
        <Grid item xs={6} md={inline ? 4 : 2}>
          <BooleanInput
            step={1}
            label="Legal Knowledge"
            source="hasLegalKnowledge"
          />
          <BooleanInput
            step={1}
            label="Language Knowledge"
            source="hasLanguageKnowledge"
          />
        </Grid>
      </Grid>
    );
  }
};

export const UserEdit = (props: EditProps): React.ReactElement => {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.target === document.body && event.key === "e") {
        window.open("/#/User/" + props.id + "/show", "_self");
      }
    },
    [props.id]
  );

  const notify = useNotify();

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Edit
      {...props}
      onSuccess={
        !props.options?.inline
          ? undefined
          : () => {
              notify("Success", "info", null, true);
              props.options?.onSuccess();
            }
      }
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={
              props.options?.inline
                ? { marginTop: 0 }
                : {
                    justifyContent: "space-between",
                    position: "fixed",
                    bottom: 0,
                    width: 240,
                    right: 0,
                  }
            }
          >
            {!props.options?.inline && (
              <DeleteWithConfirmButton
                undoable={false}
                mutationMode="pessimistic"
                label="Delete"
                variant="outlined"
              />
            )}
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <UserEditForm
          onlyShowEmployeePreferences={
            props.options?.onlyShowEmployeePreferences
          }
          inline={props.options?.inline}
        />
      </SimpleForm>
    </Edit>
  );
};
