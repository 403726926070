import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  colors,
  Divider,
  FormControlLabel,
  Input,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CloseRounded,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ConfirmationNumber,
  EditRounded,
  FileCopyOutlined,
  KeyboardArrowDown,
  LocalOfferOutlined,
  ReceiptOutlined,
  RedeemOutlined,
  SaveOutlined,
  VisibilityOutlined,
  Warning,
} from "@material-ui/icons";
import currency from "currency.js";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Button,
  EditButton,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { Address } from "../api/address/Address";
import {
  EnumAddressCountry,
  GERMAN_SPEAKING_COUNTRIES,
} from "../api/address/EnumAddressCountry";
import { Claim } from "../api/claim/Claim";
import {
  EnumPaymentPaymentType,
  NEGATIVE_PAYMENT_TYPES,
  THIRD_PARTY_PAYMENT_TYPES,
} from "../api/payment/EnumPaymentPaymentType";
import { Payment } from "../api/payment/Payment";
import { User } from "../api/user/User";
import {
  EURO_CURRENCY_FORMAT,
  PaymentCreate,
  SIMPLE_EURO_CURRENCY_FORMAT,
} from "../payment/PaymentCreate";
import { theme } from "../theme/theme";
import { calculateTaxValue } from "../util/ClaimUtils";
import { CustomSlider } from "./CustomSlider";

const useStyles = makeStyles((theme) => ({
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const ClaimShowSidebar = (props: any) => {
  const record: Claim = useRecordContext();
  const numberFormat = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: record?.currency || "EUR",
  });
  const numberFormatEUR = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const [creditor, setCreditor] = React.useState<User | null>(null);
  const [debtor, setDebtor] = React.useState<User | null>(null);
  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [paymentDataLoaded, setPaymentDataLoaded] =
    React.useState<boolean>(false);
  const [showAmountDetails, setShowAmountDetails] =
    React.useState<boolean>(false);
  const [includeSuccessFeeInCancellation, setIncludeSuccessFeeInCancellation] =
    React.useState<boolean>(true);

  const [sliderFactorCreditor, setSliderFactorCreditor] = useState(100);
  const [positionAmountToSave, setPositionAmountToSave] = useState("");
  const [positionIdToSave, setPositionIdToSave] = useState("");

  const [absoluteTotal, setAbsoluteTotal] = useState(0);
  const [showFactorCalculator, setShowFactorCalculator] = useState(false);
  const [sliderFactorDebtCollector, setSliderFactorDebtCollector] =
    useState(100);

  const handleChangeFactorCreditor = (_event, newValue) => {
    setSliderFactorCreditor(newValue);
  };
  const handleChangeFactorDebtCollector = (_event, newValue) => {
    setSliderFactorDebtCollector(newValue);
  };

  React.useEffect(() => {
    if (record?.creditor?.id) {
      dataProvider
        .getOne<User>("User", {
          id: record?.creditor?.id,
        })
        .then(({ data }) => {
          setCreditor(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [record?.creditor?.id, dataProvider]);

  React.useEffect(() => {
    if (record?.debtor?.id) {
      dataProvider
        .getOne<User>("User", {
          id: record?.debtor?.id,
        })
        .then(({ data }) => {
          setDebtor(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataProvider, record?.debtor?.id]);

  const [creditorAddress, setCreditorAddress] = React.useState<Address | null>(
    null
  );
  React.useEffect(() => {
    if (creditor?.address?.id) {
      dataProvider
        .getOne<Address>("Address", {
          id: creditor?.address?.id,
        })
        .then(({ data }) => {
          setCreditorAddress(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [creditor?.address?.id, dataProvider]);

  const [debtorAddress, setDebtorAddress] = useState<Address | null>(null);

  const [showDebtCollectorFeeAndExpenses, setShowDebtCollectorFeeAndExpenses] =
    useState<boolean>(false);

  React.useEffect(() => {
    if (debtor?.address?.id) {
      dataProvider
        .getOne<Address>("Address", {
          id: debtor?.address?.id,
        })
        .then(({ data }) => {
          setDebtorAddress(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [debtor?.address?.id, dataProvider]);

  React.useEffect(() => {
    if (props.claimId) {
      dataProvider
        .getList<Payment>("Payment", {
          filter: { claim: { id: props.claimId } },
          pagination: {
            page: 1,
            perPage: 500,
          },
          sort: { field: "paymentDate", order: "DESC" },
        })
        .then(({ data }) => {
          setPayments(data as Payment[]);
          setPaymentDataLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setPaymentDataLoaded(true);
        });
    }
  }, [props.claimId, record?.totalPending, dataProvider]);

  // latest costs
  const latestDunningCostExpense = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.DunningCostExpense
  );
  const latestDunningCostFee = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DunningCostFee
  );
  const latestDefaultInterest = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DefaultInterest
  );
  const latestCreditorExtra = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.ExistingCreditorExtras
  );
  const latestCreditorInterest = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.CreditorInterest
  );
  const latestDebtCollectorTax = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.DebtCollectorTax
  );

  const incomingPayments = payments.filter(
    (payment) =>
      payment.paymentType &&
      NEGATIVE_PAYMENT_TYPES.includes(
        payment.paymentType as EnumPaymentPaymentType
      ) &&
      payment.paymentType !== EnumPaymentPaymentType.WriteOffDiscount &&
      payment.paymentType !== EnumPaymentPaymentType.ExistingPayments &&
      !THIRD_PARTY_PAYMENT_TYPES.includes(
        payment.paymentType as EnumPaymentPaymentType
      )
  );
  const incomingPaymentsTotal: number = parseFloat(
    incomingPayments
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const claimPositions = payments.filter(
    (payment) =>
      payment.paymentType &&
      payment.paymentType === EnumPaymentPaymentType.ClaimPosition
  );

  const writeOffs = payments.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.WriteOffDiscount
  );
  const writeOffsTotal: number = parseFloat(
    writeOffs
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const existingPayments = payments.filter(
    (payment) => payment.paymentType === EnumPaymentPaymentType.ExistingPayments
  );
  const existingPaymentsTotal: number = parseFloat(
    existingPayments
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const payouts = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount >= 0
  );
  const payoutsTotal: number = parseFloat(
    payouts
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const negativePayouts = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount < 0
  );

  const debtCollectorFees = payments.filter(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.DebtCollectorFee &&
      payment.amount >= 0
  );
  const debtCollectorFeesTotal: number = parseFloat(
    debtCollectorFees
      .reduce(function (a, b) {
        return a + b.amount;
      }, 0)
      ?.toFixed(2)
  );

  const latestPayout = payments.find(
    (payment) => payment.paymentType === EnumPaymentPaymentType.Payout
  );
  const latestActualPayout = payments.find(
    (payment) =>
      payment.paymentType === EnumPaymentPaymentType.Payout &&
      payment.amount > 0
  );
  const today = Date.now();
  let latestPayoutDate: number = today;
  let latestActualPayoutDate: number = today;
  if (latestPayout?.paymentDate) {
    latestPayoutDate = Date.parse(latestPayout?.paymentDate?.toString());
  }
  if (latestActualPayout?.paymentDate) {
    latestActualPayoutDate = Date.parse(
      latestActualPayout?.paymentDate?.toString()
    );
  }

  // everything together
  useEffect(() => {
    setAbsoluteTotal(
      parseFloat(
        (
          (record?.originalAmountDue || 0) * (sliderFactorCreditor / 100) +
          (latestDunningCostFee?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestDunningCostExpense?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestDefaultInterest?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestCreditorExtra?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          (latestCreditorInterest?.amount || 0) * (sliderFactorCreditor / 100) +
          (latestDebtCollectorTax?.amount || 0) *
            (sliderFactorDebtCollector / 100) +
          existingPaymentsTotal +
          writeOffsTotal
        )?.toFixed(2)
      )
    );
    props.setAbsoluteTotal(absoluteTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    record?.originalAmountDue,
    sliderFactorCreditor,
    latestDunningCostFee?.amount,
    sliderFactorDebtCollector,
    latestDunningCostExpense?.amount,
    latestDefaultInterest?.amount,
    latestCreditorExtra?.amount,
    latestCreditorInterest?.amount,
    latestDebtCollectorTax?.amount,
    existingPaymentsTotal,
    writeOffsTotal,
    absoluteTotal,
  ]);

  if (!record) {
    return null;
  }

  let currentTotal: number = parseFloat(
    (absoluteTotal + incomingPaymentsTotal)?.toFixed(2)
  );
  const paymentsBeforeLastPayout = payments.filter(
    (payment) =>
      (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
        payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
      Date.parse(payment.paymentDate?.toString()) < latestPayoutDate
  );
  const paymentsBeforeLastPayoutTotal = parseFloat(
    Math.abs(
      paymentsBeforeLastPayout.reduce(function (a, b) {
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  let paymentsAfterLastPayout =
    latestActualPayoutDate !== today
      ? payments.filter(
          (payment) =>
            (payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
              payment.paymentType === EnumPaymentPaymentType.PaymentRate) &&
            Date.parse(payment.paymentDate?.toString()) > latestPayoutDate
        )
      : payments.filter(
          (payment) =>
            payment.paymentType === EnumPaymentPaymentType.DebtClearance ||
            payment.paymentType === EnumPaymentPaymentType.PaymentRate
        );
  const paymentsAfterLastPayoutTotal = parseFloat(
    Math.abs(
      paymentsAfterLastPayout.reduce(function (a, b) {
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  let currentClaimSumMinusCosts = parseFloat(
    (Math.abs(incomingPaymentsTotal) - payoutsTotal)?.toFixed(2)
  );

  let deductDunningCostExpenseFromPendingPayout = false;
  let deductDunningCostFeeFromPendingPayout = false;
  let deductDefaultInterestFromPendingPayout = false;
  let deductExistingCreditorExtrasFromPendingPayout = false;

  let debtistCostsTotal: number = parseFloat(
    (
      (latestCreditorExtra?.amount || 0) +
      (latestDefaultInterest?.amount || 0) +
      (latestDunningCostFee?.amount || 0) +
      (latestDunningCostExpense?.amount || 0)
    )?.toFixed(2)
  );
  let debtistCostsPending: number = 0;

  let debtistTaxTotal: number = 0;
  let debtistTaxPending: number = 0;

  if (debtistCostsTotal > 0 && creditorAddress?.country === "DEU") {
    debtistTaxTotal = calculateTaxValue(debtistCostsTotal, 0.19);

    debtistCostsTotal = debtistCostsTotal + debtistTaxTotal;
  }

  if (!latestPayout || debtistCostsTotal > paymentsBeforeLastPayoutTotal) {
    // no payouts yet, always deduct
    if (!latestPayout) {
      deductExistingCreditorExtrasFromPendingPayout = true;
      deductDunningCostExpenseFromPendingPayout = true;
      deductDunningCostFeeFromPendingPayout = true;
      deductDefaultInterestFromPendingPayout = true;

      debtistCostsPending = debtistCostsTotal;
    } else {
      // fees not yet paid in total, maybe partly
      if (
        (latestDefaultInterest?.amount || 0) > paymentsBeforeLastPayoutTotal
      ) {
        deductDefaultInterestFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostExpenseFromPendingPayout = true;
      }
      if (
        (latestDefaultInterest?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductDunningCostFeeFromPendingPayout = true;
      }
      if (
        (latestCreditorExtra?.amount || 0) +
          (latestDunningCostExpense?.amount || 0) +
          (latestDunningCostFee?.amount || 0) +
          (latestCreditorExtra?.amount || 0) >
        paymentsBeforeLastPayoutTotal
      ) {
        deductExistingCreditorExtrasFromPendingPayout = true;
      }

      debtistCostsPending = Math.max(
        0,
        debtistCostsTotal - paymentsBeforeLastPayoutTotal
      );
      if (creditorAddress?.country === "DEU") {
        debtistTaxPending = calculateTaxValue(debtistCostsPending, 0.19);
      }
    }
  }

  currentClaimSumMinusCosts = parseFloat(
    (
      parseFloat(
        (
          (currentClaimSumMinusCosts -
            (latestCreditorExtra?.amount || 0) -
            (latestDefaultInterest?.amount || 0) -
            (latestDunningCostFee?.amount || 0) -
            (latestDunningCostExpense?.amount || 0) -
            (latestDebtCollectorTax?.amount || 0)) *
          100
        )?.toFixed(0)
      ) / 100
    )?.toFixed(2)
  );

  let toBePaidOutTotal: number =
    record?.stage !== "Reminder" ? currentClaimSumMinusCosts : 0;
  if (
    toBePaidOutTotal > 0 &&
    !latestDebtCollectorTax?.amount &&
    creditorAddress?.country === "DEU"
  ) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(((toBePaidOutTotal - debtistTaxTotal) * 100)?.toFixed(0)) /
        100
      )?.toFixed(2)
    );
  }

  let relevantSuccessFee = 0;
  let successFeeTax = 0;
  const successFeePercentage = creditor?.successFeeInPercent || 0;
  let relevantTotalForSuccessFee = Math.min(
    currentClaimSumMinusCosts,
    paymentsAfterLastPayoutTotal
  );
  let isAvailableForSuccessFee = false;

  if (
    successFeePercentage &&
    debtorAddress?.country &&
    !GERMAN_SPEAKING_COUNTRIES.includes(
      debtorAddress.country as EnumAddressCountry
    )
  ) {
    isAvailableForSuccessFee = true;
    if (paymentsAfterLastPayoutTotal > 0) {
      relevantSuccessFee = parseFloat(
        (
          relevantTotalForSuccessFee *
          parseFloat((successFeePercentage / 100).toFixed(2))
        ).toFixed(2)
      );
    } else {
      relevantSuccessFee = debtCollectorFeesTotal;
    }
  }

  if (relevantSuccessFee > 0) {
    if (creditorAddress?.country === "DEU" && relevantSuccessFee) {
      successFeeTax = calculateTaxValue(relevantSuccessFee, 0.19);
    }
  }

  if (toBePaidOutTotal > 0) {
    toBePaidOutTotal = parseFloat(
      (
        parseFloat(
          (
            (toBePaidOutTotal -
              debtCollectorFeesTotal -
              calculateTaxValue(debtCollectorFeesTotal, 0.19) -
              relevantSuccessFee -
              successFeeTax) *
            100
          )?.toFixed(0)
        ) / 100
      )?.toFixed(2)
    );
  }

  const currentCreditorAmountDue: number =
    (record?.originalAmountDue || 0) -
    Math.abs(existingPaymentsTotal) -
    Math.abs(writeOffsTotal);

  const currentCreditorAmountDueInclInterest: number =
    currentCreditorAmountDue + (latestCreditorInterest?.amount || 0);
  props.setCurrentCreditorAmountDueInclInterest(
    currentCreditorAmountDueInclInterest
  );

  const thirdPartyOutboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorOutboundPosition
  );

  const thirdPartyInboundPayments = payments.filter(
    (payment) =>
      payment.paymentType ===
      EnumPaymentPaymentType.DebtCollectorInboundPosition
  );
  const thirdPartyInboundPaidPayments = thirdPartyInboundPayments.filter(
    (payment) => payment.isPlanned === false
  );
  let thirdPartyInboundPaidPaymentsTotal = parseFloat(
    Math.abs(
      thirdPartyInboundPaidPayments.reduce(function (a, b) {
        if (b.isTaxFree) {
          return a + parseFloat((b.amount * 1.19).toFixed(2));
        }
        return a + b.amount;
      }, 0)
    )?.toFixed(2)
  );

  const thirdPartyPaymentsTotal = parseFloat(
    (
      parseFloat(
        Math.abs(
          thirdPartyOutboundPayments.reduce(function (a, b) {
            return a + b.amount;
          }, 0)
        )?.toFixed(2)
      ) - thirdPartyInboundPaidPaymentsTotal
    )?.toFixed(2)
  );

  const relevantCreditorInvoiceFees = Math.max(
    parseFloat(
      (
        Math.abs(latestDunningCostExpense?.amount) +
        Math.abs(latestDunningCostFee?.amount)
      )?.toFixed(2)
    ),
    0
  );

  const successFeeForCancellation =
    relevantSuccessFee ||
    parseFloat(
      (
        record?.originalAmountDue *
        parseFloat((successFeePercentage / 100).toFixed(2))
      ).toFixed(2)
    );

  const creditorInvoiceAmount = (
    Math.min(
      relevantCreditorInvoiceFees,
      record?.originalAmountDue < 250
        ? 24.9
        : record?.originalAmountDue < 50
        ? 10
        : relevantCreditorInvoiceFees
    ) +
    (isAvailableForSuccessFee && includeSuccessFeeInCancellation
      ? successFeeForCancellation
      : 0)
  ).toFixed(2);

  const isPaid: Boolean =
    paymentDataLoaded &&
    (record?.currency && record?.currency !== "EUR"
      ? record?.totalPending <= 0
      : currentTotal <= 0);

  function sliderFormat(value) {
    return `${value}%`;
  }

  return (
    <Box
      className={classes.hideOnMobile}
      style={{
        maxWidth: 260,
        width: "100%",
        margin: "0 0 0 1.5em",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <ClaimEditActions
        setShowFactorCalculator={setShowFactorCalculator}
        showFactorCalculator={showFactorCalculator}
      />
      {showFactorCalculator && (
        <Card
          style={{
            marginBottom: 20,
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              style={{
                marginBottom: 10,
              }}
            >
              Vergleich
            </Typography>
            <Typography variant="caption">Gläubiger</Typography>
            <CustomSlider
              defaultValue={100}
              value={sliderFactorCreditor}
              onChange={handleChangeFactorCreditor}
              color="primary"
              valueLabelFormat={sliderFormat}
              step={1}
              valueLabelDisplay="auto"
            />
            <Typography variant="caption">Inkasso</Typography>
            <CustomSlider
              defaultValue={100}
              value={sliderFactorDebtCollector}
              onChange={handleChangeFactorDebtCollector}
              color="secondary"
              valueLabelFormat={sliderFormat}
              step={1}
              valueLabelDisplay="auto"
            />
          </CardContent>
        </Card>
      )}
      {paymentDataLoaded && (
        <Card
          style={{
            marginBottom: 20,
          }}
        >
          <CardContent>
            <Typography variant="h6">Balance</Typography>
            <hr />
            {record?.originalAmountDue &&
              currentCreditorAmountDueInclInterest !==
                record?.originalAmountDue && (
                <Typography variant="body2">
                  Ursprungssumme:{" "}
                  {numberFormat.format(record?.originalAmountDue)}
                </Typography>
              )}
            {existingPaymentsTotal !== 0 && (
              <Typography variant="body2" style={{ color: "grey" }}>
                Gläubiger Zahlungen:{" "}
                {numberFormat.format(existingPaymentsTotal)}
              </Typography>
            )}
            {writeOffsTotal !== 0 && (
              <Typography variant="body2" style={{ color: "grey" }}>
                Abschreibungen: {numberFormat.format(writeOffsTotal)}
              </Typography>
            )}
            {currentCreditorAmountDue !== record?.originalAmountDue &&
              currentCreditorAmountDue !==
                currentCreditorAmountDueInclInterest && (
                <Typography
                  variant="body2"
                  style={{ fontWeight: 600, color: "grey" }}
                >
                  Zwischensumme: {numberFormat.format(currentCreditorAmountDue)}
                </Typography>
              )}
            {latestCreditorInterest && (
              <Typography variant="body2">
                Gläubigerzinsen:{" "}
                {numberFormat.format(
                  parseFloat(
                    (
                      (latestCreditorInterest.amount * sliderFactorCreditor) /
                      100
                    ).toFixed(2)
                  )
                )}
              </Typography>
            )}
            {record?.originalAmountDue && (
              <>
                <Accordion
                  variant="outlined"
                  square
                  style={{
                    border: 0,
                    background: "transparent",
                    padding: 0,
                    margin: 0,
                  }}
                  expanded={showAmountDetails}
                  onChange={() => {
                    setShowAmountDetails(!showAmountDetails);
                  }}
                >
                  <AccordionSummary
                    style={{
                      padding: 0,
                      margin: 0,
                      userSelect: "text",
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Hauptf. gesamt:{" "}
                      {numberFormat.format(
                        parseFloat(
                          (
                            currentCreditorAmountDueInclInterest *
                            (sliderFactorCreditor / 100)
                          ).toFixed(2)
                        )
                      )}
                      <KeyboardArrowDown
                        style={{ float: "right", marginTop: -2 }}
                      />
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails
                    style={{
                      padding: 0,
                      margin: 0,
                      width: "100%",
                      display: "inline-block",
                      wordBreak: "break-word",
                      whiteSpace: "pre-line",
                    }}
                  >
                    <Typography variant="body2" align="right">
                      <TableContainer>
                        <Table size="small" style={{ marginBottom: 8 }}>
                          <TableBody>
                            {claimPositions.length > 0 ? (
                              claimPositions.map((position) => {
                                return (
                                  <TableRow
                                    style={{
                                      ...(position.isPlanned && {
                                        textDecoration: "line-through",
                                      }),
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      variant="footer"
                                      size="small"
                                      style={{
                                        color: theme.palette.grey[600],
                                        lineHeight: "18px",
                                        padding: "6px 2px",
                                        maxWidth: 100,
                                        minWidth: 100,
                                      }}
                                    >
                                      <div
                                        style={{
                                          color: theme.palette.grey[900],
                                        }}
                                      >
                                        {position.reference?.replace(
                                          " (initial)",
                                          ""
                                        )}
                                      </div>
                                      <div>
                                        {format(
                                          new Date(position.paymentDate),
                                          "dd.MM.yyyy"
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      padding="none"
                                      align="right"
                                      variant="footer"
                                      size="small"
                                      style={{
                                        color: theme.palette.primary.main,
                                        minWidth: 80,
                                      }}
                                    >
                                      {position.id === positionIdToSave ? (
                                        <Input
                                          value={positionAmountToSave?.toString()}
                                          onChange={(e) => {
                                            const newValue =
                                              e.target.value.replaceAll(
                                                /[^\d.]/g,
                                                ""
                                              );
                                            setPositionAmountToSave(newValue);
                                          }}
                                          onPaste={async (e) => {
                                            e.preventDefault();
                                            let pasteContent =
                                              e.clipboardData.getData("text");
                                            if (pasteContent) {
                                              const parsedInput =
                                                EURO_CURRENCY_FORMAT.test(
                                                  pasteContent
                                                ) ||
                                                SIMPLE_EURO_CURRENCY_FORMAT.test(
                                                  pasteContent
                                                )
                                                  ? currency(
                                                      pasteContent
                                                        .replace(".", "")
                                                        .replace(",", "."),
                                                      {
                                                        separator: "",
                                                        decimal: ".",
                                                        symbol: "€",
                                                      }
                                                    ).value
                                                  : currency(pasteContent, {
                                                      separator: ",",
                                                      decimal: ".",
                                                      symbol: "$",
                                                    }).value;

                                              if (!isNaN(parsedInput)) {
                                                setPositionAmountToSave(
                                                  parsedInput.toString()
                                                );
                                              }
                                            }
                                          }}
                                          defaultValue={position.amount?.toString()}
                                        />
                                      ) : (
                                        numberFormat.format(position.amount)
                                      )}
                                    </TableCell>
                                    <TableCell
                                      padding="none"
                                      align="right"
                                      variant="footer"
                                      size="small"
                                    >
                                      {(!record?.currency ||
                                        record?.currency === "EUR") &&
                                        !position.isPlanned && (
                                          <>
                                            {position.id !==
                                            positionIdToSave ? (
                                              <EditRounded
                                                style={{
                                                  height: 16,
                                                  cursor: "pointer",
                                                  color:
                                                    theme.palette.warning.main,
                                                  padding: 5,
                                                  paddingTop: 10,
                                                  paddingBottom: 10,
                                                  paddingRight: 10,
                                                  marginLeft: -5,
                                                  verticalAlign: "middle",
                                                  zIndex: 3,
                                                }}
                                                onClick={(e) => {
                                                  e.stopPropagation();

                                                  setPositionAmountToSave(
                                                    position.amount?.toString()
                                                  );
                                                  setPositionIdToSave(
                                                    position.id
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <Tooltip title="Save">
                                                <SaveOutlined
                                                  style={{
                                                    height: 20,
                                                    cursor: "pointer",
                                                    color:
                                                      theme.palette.success
                                                        .main,
                                                    padding: 5,
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    paddingRight: 10,
                                                    marginLeft: -5,
                                                    verticalAlign: "middle",
                                                    zIndex: 3,
                                                  }}
                                                  onClick={async (e) => {
                                                    e.stopPropagation();

                                                    await dataProvider.update(
                                                      "Claim",
                                                      {
                                                        id: record?.id,
                                                        data: {
                                                          originalAmountDue:
                                                            parseFloat(
                                                              (
                                                                record?.originalAmountDue -
                                                                position.amount +
                                                                Number(
                                                                  positionAmountToSave
                                                                )
                                                              ).toFixed(2)
                                                            ),
                                                        },
                                                        previousData: record,
                                                      }
                                                    );
                                                    await dataProvider.update(
                                                      "Payment",
                                                      {
                                                        id: position.id,
                                                        data: {
                                                          amount:
                                                            Number(
                                                              positionAmountToSave
                                                            ),
                                                        },
                                                        previousData: position,
                                                      }
                                                    );
                                                    refresh();
                                                    setPositionIdToSave("");
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                            {position.id !==
                                              positionIdToSave && (
                                              <CloseRounded
                                                style={{
                                                  height: 16,
                                                  cursor: "pointer",
                                                  color:
                                                    theme.palette.error.main,
                                                  padding: 5,
                                                  paddingTop: 10,
                                                  paddingBottom: 10,
                                                  paddingRight: 10,
                                                  marginLeft: -5,
                                                  verticalAlign: "middle",
                                                  zIndex: 3,
                                                }}
                                                onClick={async (e) => {
                                                  e.stopPropagation();
                                                  if (
                                                    window.confirm(
                                                      "Do you really want to remove this position?"
                                                    )
                                                  ) {
                                                    await dataProvider.update(
                                                      "Claim",
                                                      {
                                                        id: record?.id,
                                                        data: {
                                                          originalAmountDue:
                                                            parseFloat(
                                                              (
                                                                record?.originalAmountDue -
                                                                position.amount
                                                              ).toFixed(2)
                                                            ),
                                                          totalPending:
                                                            parseFloat(
                                                              (
                                                                record?.totalPending -
                                                                position.amount
                                                              ).toFixed(2)
                                                            ),
                                                        },
                                                        previousData: record,
                                                      }
                                                    );
                                                    await dataProvider.update(
                                                      "Payment",
                                                      {
                                                        id: position.id,
                                                        data: {
                                                          isPlanned: true,
                                                        },
                                                        previousData: position,
                                                      }
                                                    );
                                                    refresh();
                                                  }
                                                }}
                                              />
                                            )}
                                          </>
                                        )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow style={{}}>
                                <TableCell
                                  component="th"
                                  variant="footer"
                                  size="small"
                                  style={{
                                    color: theme.palette.grey[600],
                                    lineHeight: "18px",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: theme.palette.grey[900],
                                    }}
                                  >
                                    {record?.title}
                                  </div>
                                  <div>
                                    {format(
                                      new Date(record?.invoiceCreatedAt),
                                      "dd.MM.yyyy"
                                    )}
                                  </div>
                                </TableCell>

                                <TableCell
                                  padding="none"
                                  align="right"
                                  variant="footer"
                                  size="small"
                                  style={{
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {record?.id === positionIdToSave ? (
                                    <Input
                                      value={positionAmountToSave?.toString()}
                                      onChange={(e) => {
                                        const newValue =
                                          e.target.value.replaceAll(
                                            /[^\d.]/g,
                                            ""
                                          );
                                        setPositionAmountToSave(newValue);
                                      }}
                                      defaultValue={record.originalAmountDue?.toString()}
                                    />
                                  ) : (
                                    numberFormat.format(
                                      record.originalAmountDue
                                    )
                                  )}
                                </TableCell>

                                <TableCell
                                  padding="none"
                                  align="right"
                                  variant="footer"
                                  size="small"
                                >
                                  {(!record?.currency ||
                                    record?.currency === "EUR") && (
                                    <>
                                      {record?.id !== positionIdToSave ? (
                                        <EditRounded
                                          style={{
                                            height: 16,
                                            cursor: "pointer",
                                            color: theme.palette.warning.main,
                                            padding: 5,
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            paddingRight: 10,
                                            marginLeft: -5,
                                            verticalAlign: "middle",
                                            zIndex: 3,
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            setPositionAmountToSave(
                                              record.originalAmountDue.toString()
                                            );
                                            setPositionIdToSave(record.id);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          onClick={async (e) => {
                                            e.stopPropagation();

                                            await dataProvider.update("Claim", {
                                              id: record?.id,
                                              data: {
                                                originalAmountDue: parseFloat(
                                                  Number(
                                                    positionAmountToSave
                                                  ).toFixed(2)
                                                ),
                                                totalPending: parseFloat(
                                                  (
                                                    record?.totalPending -
                                                    record?.originalAmountDue +
                                                    Number(positionAmountToSave)
                                                  ).toFixed(2)
                                                ),
                                              },
                                              previousData: record,
                                            });
                                            refresh();
                                            setPositionIdToSave("");
                                          }}
                                          label={"OK"}
                                          style={{
                                            backgroundColor:
                                              theme.palette.success.dark,
                                            minWidth: 30,
                                            maxWidth: 50,
                                            padding: 5,
                                          }}
                                          size={"small"}
                                          variant={"contained"}
                                        />
                                      )}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <hr />
                      <div
                        style={{
                          maxWidth: 250,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: 600,
                            textAlign: "left",
                            marginBottom: 0,
                            marginTop: 10,
                            color: theme.palette.grey[800],
                          }}
                        >
                          New position:
                        </Typography>
                        <PaymentCreate
                          basePath="/Payment"
                          resource="Payment"
                          options={{
                            inline: true,
                            claimId: record?.id,
                            paymentType: "ClaimPosition",
                          }}
                        />
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {payoutsTotal > 0 && (
              <>
                {(!record?.currency || record?.currency === "EUR") && (
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    Hauptf. offen:{" "}
                    {numberFormat.format(
                      Math.max(
                        parseFloat(
                          (
                            (currentCreditorAmountDueInclInterest -
                              payoutsTotal -
                              (latestDebtCollectorTax?.amount
                                ? 0
                                : debtistTaxTotal)) *
                            (sliderFactorCreditor / 100)
                          ).toFixed(2)
                        ),
                        0
                      )
                    )}
                    <Tooltip title="Copy amount">
                      <FileCopyOutlined
                        style={{
                          height: 13,
                          cursor: "copy",
                          color: "var(--black10)",
                          padding: 3,
                          verticalAlign: "middle",
                          zIndex: 3,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(
                            numberFormat.format(
                              Math.max(
                                parseFloat(
                                  (
                                    (currentCreditorAmountDueInclInterest -
                                      payoutsTotal -
                                      (latestDebtCollectorTax?.amount
                                        ? 0
                                        : debtistTaxTotal)) *
                                    (sliderFactorCreditor / 100)
                                  ).toFixed(2)
                                ),
                                0
                              )
                            )
                          );
                          notify("Copied to clipboard", "info", null, false);
                        }}
                      />
                    </Tooltip>
                  </Typography>
                )}
                <hr />
              </>
            )}

            {latestDunningCostFee &&
            latestDunningCostFee.amount &&
            latestDunningCostExpense &&
            latestDunningCostExpense.amount ? (
              <Accordion
                variant="outlined"
                square
                style={{
                  border: 0,
                  background: "transparent",
                  padding: 0,
                  margin: 0,
                }}
                expanded={showDebtCollectorFeeAndExpenses}
                onClick={(e) => {
                  if (!showDebtCollectorFeeAndExpenses) {
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  setShowDebtCollectorFeeAndExpenses(
                    !showDebtCollectorFeeAndExpenses
                  );
                }}
              >
                <AccordionSummary
                  style={{
                    padding: 0,
                    margin: 0,
                    userSelect: "text",
                    wordBreak: "break-word",
                    minWidth: 350,
                    display: "inline-block",
                    whiteSpace: "pre-line",
                  }}
                  onClick={(e) => {
                    if (!showDebtCollectorFeeAndExpenses) {
                      e.stopPropagation();
                    }
                  }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography variant="body2">
                    RVG + Auslagen:{" "}
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          (latestDunningCostFee.amount +
                            latestDunningCostExpense.amount) *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    wordBreak: "break-word",
                    minWidth: 350,
                    display: "inline-block",
                    whiteSpace: "pre-line",
                    padding: 0,
                  }}
                >
                  <Typography variant="body2">
                    RVG Gebühr:{" "}
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostFee.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                  <Typography variant="body2">
                    RVG Auslagen:{" "}
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostExpense.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                {latestDunningCostFee && (
                  <Typography variant="body2">
                    RVG Gebühr:{" "}
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostFee.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                )}
                {latestDunningCostExpense && (
                  <Typography variant="body2">
                    RVG Auslagen:{" "}
                    {numberFormatEUR.format(
                      parseFloat(
                        (
                          latestDunningCostExpense.amount *
                          (sliderFactorDebtCollector / 100)
                        ).toFixed(2)
                      )
                    )}
                  </Typography>
                )}
              </>
            )}
            {latestDefaultInterest && (
              <Typography variant="body2">
                Zinsen{" "}
                <span style={{ color: theme.palette.grey[500] }}>
                  ab {format(new Date(record.dueDate), "dd.MM.yy")}
                </span>
                :{" "}
                {numberFormatEUR.format(
                  parseFloat(
                    (
                      latestDefaultInterest.amount *
                      (sliderFactorDebtCollector / 100)
                    ).toFixed(2)
                  )
                )}
              </Typography>
            )}
            {latestCreditorExtra && (
              <Typography variant="body2">
                Mahnkosten Mandant:{" "}
                {numberFormatEUR.format(
                  parseFloat(
                    (
                      latestCreditorExtra.amount *
                      (sliderFactorDebtCollector / 100)
                    ).toFixed(2)
                  )
                )}
              </Typography>
            )}
            {paymentDataLoaded &&
              record?.currency &&
              sliderFactorDebtCollector === 100 &&
              record?.currency !== "EUR" && (
                <>
                  <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                  <Typography variant="body2">
                    ={" "}
                    {numberFormat.format(
                      record?.totalPending +
                        record?.totalPaid -
                        record?.originalAmountDue
                    )}{" "}
                    Nebenforderung
                  </Typography>
                </>
              )}
            {latestDebtCollectorTax && (
              <>
                <hr />
                <Typography variant="body2">
                  Steuer, Debtist:{" "}
                  {numberFormatEUR.format(
                    parseFloat(
                      (
                        latestDebtCollectorTax.amount *
                        (sliderFactorDebtCollector / 100)
                      ).toFixed(2)
                    )
                  )}
                </Typography>
              </>
            )}
            {incomingPaymentsTotal !== 0 && (
              <>
                <hr />
                <Typography variant="body2" style={{ color: "green" }}>
                  Zahlungen erhalten:{" "}
                  {numberFormatEUR.format(incomingPaymentsTotal)}
                </Typography>
              </>
            )}
            <>
              <hr />
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  ...(isPaid && {
                    color: "green",
                  }),
                }}
              >
                Gesamt:{" "}
                {paymentDataLoaded &&
                  (record?.currency && record?.currency !== "EUR"
                    ? numberFormat.format(record?.totalPending)
                    : numberFormat.format(currentTotal))}
                {paymentDataLoaded &&
                  currentTotal !==
                    parseFloat(record?.totalPending?.toFixed(2)) &&
                  currentTotal >= 0 &&
                  sliderFactorCreditor === 100 &&
                  sliderFactorDebtCollector === 100 &&
                  record?.currency &&
                  record?.currency === "EUR" && (
                    <Warning
                      titleAccess="Betrag evtl. inkonsistent"
                      style={{
                        position: "relative",
                        top: 3,
                        height: 16,
                        color: theme.palette.error.main,
                      }}
                    />
                  )}
                <Tooltip title="Copy amount">
                  <FileCopyOutlined
                    style={{
                      height: 15,
                      cursor: "copy",
                      color: "var(--black10)",
                      padding: 3,
                      verticalAlign: "middle",
                      zIndex: 3,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(
                        paymentDataLoaded &&
                          (record?.currency && record?.currency !== "EUR"
                            ? numberFormat.format(record?.totalPending)
                            : numberFormat.format(currentTotal))
                      );
                      notify("Copied to clipboard", "info", null, false);
                    }}
                  />
                </Tooltip>
              </Typography>
            </>
          </CardContent>
        </Card>
      )}

      {paymentDataLoaded && (
        <Card
          style={{
            marginBottom: 20,
          }}
        >
          <CardContent>
            <Typography variant="h6">Auszahlungen</Typography>
            <hr />
            {incomingPaymentsTotal !== 0 && (
              <Typography variant="body2" style={{ color: "green" }}>
                Zahlungen erhalten:{" "}
                {numberFormatEUR.format(Math.abs(incomingPaymentsTotal))}
              </Typography>
            )}
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
                color: payoutsTotal > 0 ? "green" : "",
              }}
            >
              Bereits ausgezahlt:{" "}
              {payoutsTotal > 0
                ? "-" + numberFormatEUR.format(payoutsTotal)
                : 0}
            </Typography>
            <hr />
            {latestDunningCostFee && (
              <Typography
                variant="body2"
                style={
                  deductDunningCostFeeFromPendingPayout
                    ? {}
                    : { textDecoration: "line-through" }
                }
              >
                RVG Gebühr:{" "}
                {numberFormatEUR.format(-Math.abs(latestDunningCostFee.amount))}
              </Typography>
            )}
            {latestDunningCostExpense && (
              <Typography
                variant="body2"
                style={
                  deductDunningCostExpenseFromPendingPayout
                    ? {}
                    : { textDecoration: "line-through" }
                }
              >
                RVG Auslagen:{" "}
                {numberFormatEUR.format(
                  -Math.abs(latestDunningCostExpense.amount)
                )}
              </Typography>
            )}
            {latestDefaultInterest && (
              <Typography
                variant="body2"
                style={
                  deductDefaultInterestFromPendingPayout
                    ? {}
                    : { textDecoration: "line-through" }
                }
              >
                Zinsen:{" "}
                {numberFormatEUR.format(
                  -Math.abs(latestDefaultInterest.amount)
                )}
              </Typography>
            )}
            {latestCreditorExtra && (
              <Typography
                variant="body2"
                style={
                  deductExistingCreditorExtrasFromPendingPayout
                    ? {}
                    : { textDecoration: "line-through" }
                }
              >
                Mahnkosten Mandant:{" "}
                {numberFormatEUR.format(-Math.abs(latestCreditorExtra.amount))}
              </Typography>
            )}
            {record?.originalAmountDue > 10 &&
              debtistCostsPending > 0 &&
              Math.abs(latestDunningCostExpense?.amount) +
                Math.abs(latestDunningCostFee?.amount) >
                0 && (
                <>
                  <Button
                    href={
                      "/#/Payment/create?claimId=" +
                      record?.id +
                      "&paymentType=Payout" +
                      "&amount=-" +
                      creditorInvoiceAmount
                    }
                    disabled={
                      creditor?.disableCancellationFee ||
                      negativePayouts.length > 0
                    }
                    startIcon={<ReceiptOutlined />}
                    label={
                      creditor?.disableCancellationFee
                        ? "Keine Stornogebühr"
                        : "Storno" +
                          (isAvailableForSuccessFee &&
                          includeSuccessFeeInCancellation
                            ? "+EP "
                            : " ") +
                          numberFormatEUR.format(
                            parseFloat(creditorInvoiceAmount)
                          )
                    }
                    size={"medium"}
                    style={{
                      backgroundColor:
                        !creditor?.disableCancellationFee &&
                        !negativePayouts.length
                          ? theme.palette.error.dark
                          : theme.palette.grey[300],
                      minWidth: "100%",
                      marginTop: 10,
                      fontSize: 12,
                    }}
                    variant={"contained"}
                  />
                  {isAvailableForSuccessFee && (
                    <FormControlLabel
                      label="Erfolgsprämie"
                      value={includeSuccessFeeInCancellation}
                      onChange={(_e, checked) => {
                        setIncludeSuccessFeeInCancellation(checked);
                      }}
                      control={
                        <Switch
                          color="secondary"
                          defaultChecked={includeSuccessFeeInCancellation}
                        />
                      }
                      labelPlacement="end"
                      style={{ marginRight: 10, marginTop: 14, zoom: 0.8 }}
                    />
                  )}
                  {isAvailableForSuccessFee &&
                    includeSuccessFeeInCancellation && (
                      <Button
                        href={
                          "/#/Payment/create?claimId=" +
                          record?.id +
                          "&paymentType=Payout" +
                          "&amount=-" +
                          successFeeForCancellation +
                          "&reference=Success"
                        }
                        startIcon={<ReceiptOutlined />}
                        label={
                          "Erfolgsprämie " +
                          numberFormatEUR.format(
                            relevantSuccessFee ||
                              parseFloat(
                                (
                                  record?.originalAmountDue *
                                  parseFloat(
                                    (successFeePercentage / 100).toFixed(2)
                                  )
                                ).toFixed(2)
                              )
                          )
                        }
                        size={"medium"}
                        style={{
                          backgroundColor: theme.palette.warning.dark,
                          minWidth: "100%",
                          marginTop: 10,
                          fontSize: 12,
                        }}
                        variant={"contained"}
                      />
                    )}
                </>
              )}
            {debtistCostsTotal > 0 && (
              <>
                <hr />
                <Typography variant="body2">
                  Inkasso offen: {numberFormatEUR.format(debtistCostsPending)}
                </Typography>
                <Typography variant="body2" style={{ color: colors.grey[500] }}>
                  Inkasso beglichen:&nbsp;
                  {numberFormatEUR.format(
                    debtistCostsTotal - debtistCostsPending
                  )}
                </Typography>
                <Typography variant="body2">
                  Inkasso gesamt:&nbsp;
                  {numberFormatEUR.format(
                    Math.abs(debtistCostsTotal - debtistTaxTotal)
                  )}
                </Typography>
                <Typography variant="body2">
                  <b>
                    Inkasso brutto:&nbsp;
                    {numberFormatEUR.format(Math.abs(debtistCostsTotal))}
                  </b>
                </Typography>
              </>
            )}
            {(debtistTaxTotal > 0 || debtistTaxPending > 0) && (
              <>
                <hr />
                <Typography variant="body2" style={{ color: colors.grey[500] }}>
                  Steuer, Inkasso offen: -
                  {numberFormatEUR.format(debtistTaxPending)}
                  <br />
                  Steuer, Inkasso gesamt: -
                  {numberFormatEUR.format(debtistTaxTotal)}
                  <br />
                </Typography>
              </>
            )}
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
                color: toBePaidOutTotal > 0 ? "red" : "",
                marginBottom: relevantSuccessFee ? 0 : 10,
              }}
            >
              Ausstehend:{" "}
              {numberFormatEUR.format(Math.max(toBePaidOutTotal, 0))}
            </Typography>
            {!!relevantSuccessFee && !!paymentsBeforeLastPayoutTotal && (
              <Typography
                variant="body2"
                style={{
                  color: toBePaidOutTotal > 0 ? "red" : "",
                  marginBottom: 10,
                }}
              >
                exkl. Erfolgsgebühr brutto:{" "}
                {numberFormatEUR.format(
                  Math.max(
                    parseFloat((relevantSuccessFee + successFeeTax).toFixed(2)),
                    0
                  )
                )}
              </Typography>
            )}
            {debtCollectorFeesTotal > 0 && (
              <Typography
                variant="body2"
                style={{
                  color: toBePaidOutTotal > 0 ? "red" : "",
                  marginBottom: 10,
                }}
              >
                Bereits angefallene Gläubigergebühren:&nbsp;
                {numberFormatEUR.format(debtCollectorFeesTotal)}
              </Typography>
            )}
            <Button
              href={
                "/#/Payment/create?claimId=" +
                record?.id +
                "&amount=" +
                Math.max(toBePaidOutTotal, 0).toString() +
                "&paymentType=Payout" +
                (toBePaidOutTotal < 0 && record?.totalPending > 0
                  ? "&reference=Zwischenbuchung"
                  : "")
              }
              startIcon={<RedeemOutlined />}
              label={"Auszahlung"}
              style={{
                backgroundColor: theme.palette.success.dark,
                minWidth: "100%",
              }}
              size={"medium"}
              variant={"contained"}
            />
          </CardContent>
        </Card>
      )}
      {paymentDataLoaded && (
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              style={thirdPartyPaymentsTotal > 0 ? { color: "red" } : {}}
            >
              Nebenkosten{thirdPartyPaymentsTotal > 0 && " (Offen!)"}
            </Typography>
            <hr />
            <Button
              href={
                "/#/Payment/create?claimId=" +
                record?.id +
                "&paymentType=DebtCollectorOutboundPosition"
              }
              startIcon={<CloudDownloadOutlined />}
              style={{
                backgroundColor: theme.palette.grey[600],
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10,
                minWidth: "100%",
              }}
              size={"medium"}
              label="Eingehende Rechnung"
              variant="contained"
            />
            <Button
              href={
                "/#/Payment/create?claimId=" +
                record?.id +
                "&paymentType=DebtCollectorInboundPosition"
              }
              startIcon={<CloudUploadOutlined />}
              style={{
                backgroundColor: theme.palette.grey[800],
                fontSize: 12,
                minWidth: "100%",
                marginBottom: 20,
              }}
              size={"medium"}
              label="Ausgehende Rechnung"
              variant="contained"
            />
            {thirdPartyOutboundPayments?.length > 0 && (
              <List
                subheader={
                  <Typography
                    variant="body2"
                    style={{ color: "red", fontWeight: "bold" }}
                  >
                    Eingehende Rechnungen:
                  </Typography>
                }
              >
                {thirdPartyOutboundPayments.map((payment, index) => (
                  <>
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography variant={"h6"}>
                            {`${payment.reference} ${
                              payment.isPlanned ? "| (offen)" : ""
                            }`}
                          </Typography>
                        }
                        secondary={
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant={"subtitle2"}>
                              {format(
                                new Date(payment.paymentDate),
                                "dd-MM-yyyy"
                              )}
                            </Typography>
                            <Typography variant={"subtitle2"}>
                              {numberFormatEUR.format(payment.amount)}
                            </Typography>
                          </div>
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </>
                ))}
              </List>
            )}
            {thirdPartyInboundPayments?.length > 0 && (
              <Typography variant="body2" style={{ color: "green" }}>
                Ausgehende Rechnungen:{" "}
                <ul>
                  {thirdPartyInboundPayments.map((payment, index) => {
                    let amount = payment.amount;
                    if (
                      creditorAddress?.country === "DEU" &&
                      creditor.taxDeductionAllowed &&
                      !payment.isTaxFree
                    ) {
                      amount = parseFloat((amount * 1.19)?.toFixed(2));
                    }

                    return (
                      <li key={index}>
                        <>
                          {format(new Date(payment.paymentDate), "dd-MM-yyyy")}:
                        </>{" "}
                        <b>{numberFormatEUR.format(amount)}</b>
                        <br />
                        {payment.reference}
                        {payment.isPlanned && " (offen)"}
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                }}
              >
                Offene Summe:{" "}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                }}
              >
                {numberFormatEUR.format(Math.max(0, thirdPartyPaymentsTotal))}
              </Typography>
            </div>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

const ClaimEditActions = (props: any) => {
  const record = useRecordContext();

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Tooltip title="Edit Claim" placement="bottom">
          <EditButton
            basePath={"/Claim"}
            record={record}
            label={"Edit"}
            style={{
              margin: 0,
              marginRight: 0,
              minWidth: "100%",
            }}
          />
        </Tooltip>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="View payment page" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            href={
              process.env.REACT_APP_DASHBOARD_PAGE_URL +
              "/pay/" +
              record?.id +
              "/" +
              record?.reference
            }
            startIcon={<VisibilityOutlined />}
            // @ts-ignore
            target="_blank"
            rel="noreferrer"
          />
        </Tooltip>

        <Tooltip title="View Tickets" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              window.open(
                'https://debtistgmbh.freshdesk.com/a/search/tickets?filter[]=group_id%3A"103000276855%2C103000415785"&sortBy=relevance&term=' +
                  record?.reference,
                "_blank"
              );
            }}
            startIcon={<ConfirmationNumber />}
            label=""
          />
        </Tooltip>

        <Tooltip title="Show Settlement Calculator" placement="bottom">
          <Button
            style={{
              marginRight: 0,
              minWidth: "30%",
              padding: "8px 16px",
            }}
            variant={"outlined"}
            size={"medium"}
            onClick={() => {
              props.setShowFactorCalculator(!props.showFactorCalculator);
            }}
            startIcon={<LocalOfferOutlined />}
            label=""
          />
        </Tooltip>
      </div>
    </div>
  );
};
