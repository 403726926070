import * as React from "react";
import { useState } from "react";

import { Divider, Grid, Typography } from "@material-ui/core";
import qs from "qs";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  DateInput,
  NumberInput,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { ClaimTitle } from "../claim/ClaimTitle";
import { ROLES_OPTIONS } from "./RolesOptions";
import { UserTitle } from "./UserTitle";

const randomPassword =
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8);

const NameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();

  if (!manualMode) {
    let generatedName: string = "";

    if (values.contactName) {
      generatedName = values.contactName;
      if (values.businessName) {
        generatedName = generatedName + ", " + values.businessName;
      }
    } else if (values.businessName) {
      generatedName = values.businessName;
    }
    values.name = generatedName;
  }

  return (
    <TextInput
      hidden
      style={{ display: "none" }}
      label="Name (generated)"
      source="name"
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

const UsernameInput = (props: any) => {
  const [manualMode, setManualMode] = useState(false);
  const { values } = useFormState();
  if (!manualMode) {
    if (values.email) {
      values.username = values.email;
    } else {
      values.username = values.phone;
    }
  }

  return (
    <TextInput
      label="Username (generated)"
      source="username"
      InputProps={{
        className: !manualMode ? "Mui-disabled" : "",
      }}
      onFocus={() => setManualMode(true)}
      onBlur={(event) => {
        if (!event?.target?.value) {
          setManualMode(false);
        }
      }}
      {...props}
    />
  );
};

const Roles = (props: any) => {
  const { values } = useFormState();

  if (!props.isNormalUser && !values.roles) {
    values.roles = ["debtor"];
  }

  return (
    <SelectArrayInput
      style={!props.isNormalUser ? { display: "none" } : {}}
      source="roles"
      choices={ROLES_OPTIONS}
      required
      fullWidth
      optionText="label"
      optionValue="value"
    />
  );
};

const EmailInput = () => {
  const form = useForm();

  return (
    <TextInput
      fullWidth
      label="Email"
      source="email"
      type="email"
      onPaste={async (e) => {
        e.preventDefault();
        let pasteContent = e.clipboardData.getData("text");
        pasteContent = pasteContent.trim().replace(/[^a-zA-Z0-9._%+-@]+/g, "");
        form.change("email", pasteContent);
      }}
    />
  );
};

const UserCreateForm = () => {
  const parsedLocation = qs.parse(useLocation().search.slice(1));
  const { values } = useFormState();

  let isNormalUser = true;
  let isLaywerUser = false;
  let isEmployee = false;
  if (
    parsedLocation.userType &&
    parsedLocation.userType !== "Default" &&
    values &&
    values.userType !== "Default"
  ) {
    if (parsedLocation.userType === "Lawyer" || values.userType === "Lawyer") {
      isLaywerUser = true;
    }
    isNormalUser = false;
  }
  if (isNormalUser) {
    isEmployee = ["manager", "admin", "lightManager"].some((str) =>
      values.roles?.includes(str)
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <TextInput
            fullWidth
            label="Contact Name"
            source="contactName"
            autoFocus
            placeholder="Example: Max Müller"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput
            fullWidth
            label="Business Name"
            source="businessName"
            required={isLaywerUser ? true : false}
            placeholder="Incl. legal form: Debtist GmbH"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NameInput required fullWidth />
        </Grid>
        <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
          <SelectInput
            source="businessType"
            label="Business Type"
            choices={[
              { label: "B2B (Business)", value: "Business" },
              { label: "B2C (Consumer)", value: "Consumer" },
              { label: "B2C (Merchant)", value: "Merchant" },
            ]}
            defaultValue="Business"
            optionText="label"
            optionValue="value"
          />
          <SelectInput
            source="userType"
            label="User Type"
            defaultValue={parsedLocation.userType || "Default"}
            choices={[
              { label: "Default", value: "Default" },
              { label: "Lawyer", value: "Lawyer" },
              {
                label: "Insolvency Administrator",
                value: "InsolvencyAdministrator",
              },
              { label: "Additional Contact", value: "AdditionalContact" },
            ]}
            optionText="label"
            optionValue="value"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <EmailInput />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextInput fullWidth label="Phone" source="phone" />
        </Grid>
        <Grid item xs={6} md={3} style={{ textAlign: "right" }}></Grid>
        <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
          <Roles isNormalUser={isNormalUser} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <UsernameInput
            fullWidth
            required
            style={!isNormalUser ? { display: "none" } : {}}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <PasswordInput
            style={!isNormalUser ? { display: "none" } : {}}
            fullWidth
            required
            label="Password"
            source="password"
            defaultValue={randomPassword}
          />
        </Grid>
        <Grid item xs={6} md={3} style={{ textAlign: "right" }}></Grid>
        <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
          <ReferenceInput
            filterToQuery={(searchText) => ({
              name: { startsWith: searchText },
              businessName: { startsWith: "Debtist" },
            })}
            isRequired
            source="manager.id"
            reference="User"
            label="Manager"
          >
            <AutocompleteInput suggestionLimit={10} optionText={UserTitle} />
          </ReferenceInput>
        </Grid>
      </Grid>

      <Divider style={{ width: "100%" }} />
      <br />

      {isNormalUser && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <SelectInput
                fullWidth
                source="industry"
                label="Industry"
                choices={[
                  { label: "Fulfillment", value: "Fulfillment" },
                  { label: "Fintech", value: "Fintech" },
                  { label: "Marketplace", value: "Marketplace" },
                  { label: "Insurance", value: "Insurance" },
                  { label: "Healthcare", value: "Healthcare" },
                  { label: "Software", value: "Software" },
                  { label: "Mobility", value: "Mobility" },
                  { label: "Lending", value: "Lending" },
                  {
                    label: "PaymentServiceProvider",
                    value: "PaymentServiceProvider",
                  },
                  { label: "Banking", value: "Banking" },
                  { label: "Rental", value: "Rental" },
                  { label: "ECommerce", value: "ECommerce" },
                  { label: "Logistics", value: "Logistics" },
                  { label: "Wholesale", value: "Wholesale" },
                  { label: "Construction", value: "Construction" },
                ]}
                optionText="label"
                allowEmpty
                optionValue="value"
              />
              <TextInput fullWidth label="VAT Id" source="vatId" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DateInput label="Birthday" fullWidth source="birthday" />
              <TextInput label="Image URL" source="imageUrl" />
            </Grid>
            <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
              <NumberInput
                step={1}
                label="Max. Zahlungsplan Monate"
                source="maximumPaymentPlanMonths"
                defaultValue={12}
              />
              <NumberInput
                step={1}
                label="Autom. Vergleiche % von Forderung"
                source="autoSettlementInPercent"
              />
              <NumberInput
                step={1}
                label="Erfolgspauschale Ausland %"
                source="successFeeInPercent"
              />
            </Grid>
            <Grid item xs={6} md={3} style={{ textAlign: "right" }}>
              <NumberInput
                step={1}
                label="Mahnbescheid Min.-Betrag B2C"
                source="courtOrderMinimumB2C"
              />
              <NumberInput
                step={1}
                label="Mahnbescheid Min.-Betrag B2B"
                source="courtOrderMinimumB2B"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Account Status</Typography>
              <br />
              <BooleanInput
                label="Is verified"
                source="isVerified"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                step={1}
                label="Reminder Subscription Active"
                source="hasActiveReminderSubscription"
              />
              <BooleanInput
                step={1}
                label="+ Calling for Reminders"
                source="hasActiveReminderSubscriptionWithCalling"
              />
              <BooleanInput
                step={1}
                label="Credit Rating Subscription Active"
                source="hasActiveCreditRatingSubscription"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Operational Flags</Typography>
              <br />
              <BooleanInput
                label="Keine Einwohnermeldeamt-Anfrage (EMA)"
                source="disableRegistrationOfficeEnquiry"
                defaultValue={true}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Negativpauschale"
                source="disableCancellationFee"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Anwaltliches Mahnschreiben erlaubt"
                source="allowPartnerLawyerInteraction"
                defaultValue={true}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Kein gerichtliches Verfahren"
                source="disableCourtOrder"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Streitverfahren"
                source="disableCourtLitigation"
                defaultValue={false}
                style={{ display: "inline" }}
              />
              <BooleanInput
                label="Keine Insolvenzanmeldung"
                source="disableInsolvencyFiling"
                defaultValue={true}
                style={{ display: "inline" }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">Accounting Flags</Typography>
              <br />
              <BooleanInput
                label="Vorsteuerabzugsberechtigt"
                source="taxDeductionAllowed"
                defaultValue={true}
                style={{ display: "inline" }}
              />
            </Grid>
          </Grid>
          <Divider />
          <br />
          <TextInput
            label="Custom Clauses"
            multiline
            fullWidth
            minRows={4}
            source="customClauses"
          />
          <ArrayInput
            label="Operational Recipients"
            source="operationalRecipients"
          >
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Financial Recipients" source="financialRecipients">
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput
            label="Analytical Recipients"
            source="analyticalRecipients"
          >
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
        </>
      )}
      {parsedLocation.claimId && (
        <div
          style={{
            display: "hidden",
          }}
        >
          <ReferenceArrayInput
            filterToQuery={(searchText) => ({
              reference: { startsWith: searchText },
            })}
            isRequired
            defaultValue={[parsedLocation.claimId]}
            source="claimsAsContact"
            reference="Claim"
            label="Claim"
          >
            <AutocompleteArrayInput
              suggestionLimit={5}
              optionText={ClaimTitle}
            />
          </ReferenceArrayInput>
        </div>
      )}
      <TextInput
        label="Comment"
        multiline
        fullWidth
        minRows={4}
        source="comment"
      />
      {isEmployee && (
        <Grid container spacing={3}>
          <Grid item xs={6} md={2}>
            <NumberInput
              step={1}
              label="Min. amount B2C"
              source="minimumAmountB2C"
            />
            <NumberInput
              step={1}
              label="Min. amount B2B"
              source="minimumAmountB2B"
            />
          </Grid>
          <Grid item xs={6} md={2} style={{ textAlign: "right" }}>
            <NumberInput
              step={1}
              label="Max. amount B2C"
              source="maximumAmountB2C"
            />
            <NumberInput
              step={1}
              label="Max. amount B2B"
              source="maximumAmountB2B"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <BooleanInput
              step={1}
              label="Legal Knowledge"
              source="hasLegalKnowledge"
            />
            <BooleanInput
              step={1}
              label="Language Knowledge"
              source="hasLanguageKnowledge"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const UserCreate = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const parsedLocation = qs.parse(useLocation().search.slice(1));

  const history = useHistory();

  return (
    <Create
      {...props}
      onSuccess={({ data }) => {
        notify("User created. Add address now.", "success");
        history.push(
          "/Address/create?userId=" +
            data.id +
            "&isWizard=true" +
            (parsedLocation.userType
              ? "&userType=" + parsedLocation.userType
              : "")
        );
        return false;
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar
            style={{
              justifyContent: "space-between",
              position: "fixed",
              bottom: 0,
              width: 120,
              right: 0,
            }}
          >
            <SaveButton label="Save" />
          </Toolbar>
        }
      >
        <UserCreateForm />
      </SimpleForm>
    </Create>
  );
};
